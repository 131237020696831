<template>
  <v-container fluid>
    Dashboard
  </v-container>
</template>

<script>
export default {

};
</script>

<style></style>